import React from 'react';
import styled from 'styled-components';
import Star from 'svgs/star.svg';

const STitle = styled.h1`
    font-family: 'Italiana', sans-serif;
    font-size: 6.5vw;
    line-height: 0.9;
    font-weight: normal;
    z-index: 6;

    @media (max-width: 768px), (orientation: portrait) {
        font-size: 12vw;
        margin-left: 7%;
    }
`;

const STitleWrapper = styled.div`
    margin-left: 10%;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 768px), (orientation: portrait) {
        margin: 0;
        padding: 5vw 5vw 6.5vw;
        z-index: 6;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 25%;
            height: 100%;
            z-index: -1;
            background: ${({ theme }) => theme.colors.rectangle};
        }
    }
`;

const SSubtitle = styled.div`
    font-family: 'Italiana', sans-serif;
    font-size: 1.7vw;
    margin-top: 0.5vw;

    @media (max-width: 768px), (orientation: portrait) {
        margin-top: 1.8vw;
        margin-left: 7%;
        font-size: 4vw;
    }
`;

const SStar = styled(Star)`
    height: 2.5vw;
    width: 2.5vw;
    margin-bottom: 0.9vw;

    @media (max-width: 768px), (orientation: portrait) {
        height: 4.75vw;
        width: 4.75vw;
        margin-bottom: 2.25vw;
    }
`;

const SSpan = styled.span`
    font-size: inherit;
    font-family: inherit;
    position: relative;
    display: block;

    &::before {
        content: '';
        display: inline-block;
        width: 4.6vw;
        margin-right: 0.75vw;
        border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    }

    @media (max-width: 768px), (orientation: portrait) {
        &::before {
            width: 9vw;
        }
    }
`;

export const PageTitle = () => {
    return (
        <STitleWrapper>
            <STitle>
                MATEUSZ/
                <SStar />
                <SSpan>KUROWSKI</SSpan>
            </STitle>
            <SSubtitle>designer_art</SSubtitle>
        </STitleWrapper>
    );
};
