import React from 'react';
import styled from 'styled-components';
import AboutMe from 'svgs/about_me.svg';
import Contact from 'svgs/contact.svg';
import Mail from 'svgs/mail.svg';

const SContactSection = styled.div`
    z-index: 7;
    bottom: 3rem;
    right: 0;
    width: 100%;
    padding: 3vw 0;
    height: 35%;
    justify-content: flex-end;
    display: ${({ mobile }) => (mobile ? 'none' : 'flex')};

    @media (max-width: 1024px) {
        width: unset;
        justify-content: center;
    }

    @media (max-width: 768px), (orientation: portrait) {
        order: 3;
        position: static;
        margin-top: 10vw;
        display: ${({ mobile }) => (!mobile ? 'none' : 'block')};
    }
`;

const SWrapper = styled.div`
    display: flex;
    width: 90%;

    @media (max-width: 1024px) {
        width: unset;
    }

    @media (max-width: 768px), (orientation: portrait) {
        flex-direction: column-reverse;
        align-items: center;
    }
`;

const SColumn = styled.div`
    width: 13vw;
    margin-right: 3vw;
    font-family: 'Lato', serif;
    font-size: 0.8vw;
    line-height: 1.5;

    @media (max-width: 768px), (orientation: portrait) {
        margin-right: unset;
        margin-bottom: 2rem;
        font-size: 2.5vw;
        width: 40vw;
    }
`;

const STitleWrapper = styled.div`
    margin-bottom: 1.6vw;

    &::after {
        content: '';
        display: block;
        width: 11vw;
        height: 1px;
        margin-top: 0.25rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    }

    @media (max-width: 768px), (orientation: portrait) {
        margin-bottom: 4.5vw;
        &::after {
            width: 35vw;
        }
    }
`;

const SMail = styled(Mail)`
    height: 1.5vw;
    @media (max-width: 768px), (orientation: portrait) {
        height: 4vw;
    }
`;

const SContact = styled(Contact)`
    height: 1.7vw;
    @media (max-width: 768px), (orientation: portrait) {
        height: 4vw;
    }
`;

const SAboutMe = styled(AboutMe)`
    height: 1.7vw;
    @media (max-width: 768px), (orientation: portrait) {
        height: 4vw;
    }
`;

export const ContactSection = ({ mobile }) => {
    return (
        <SContactSection mobile={mobile}>
            <SWrapper>
                <SColumn>
                    <STitleWrapper>
                        <SAboutMe />
                    </STitleWrapper>
                    I’m a graphic designer with 7 years of experience in the industry and a bachelor’s degree. It isn’t
                    only my job, it’s my passion by which I want to improve my imagination to another level and boost my
                    creativity.
                </SColumn>
                <SColumn>
                    <STitleWrapper>
                        <SContact />
                    </STitleWrapper>
                    <a href="mailto:mjkurowski@gmail.com">
                        <SMail />
                    </a>
                </SColumn>
            </SWrapper>
        </SContactSection>
    );
};
