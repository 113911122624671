import React from 'react';
import styled from 'styled-components';
import Portfolio from 'svgs/portfolio.svg';

const SGraphicDesign = styled.span`
    font-size: 5vw;
    font-family: 'Italiana', sans-serif;
    ${({ translated }) => (translated ? `transform: translateX(-5px)` : null)};
`;

const SGraphicDesignWrapper = styled.div`
    display: flex;
    max-width: 28.125rem;
    align-items: flex-end;

    @media (max-width: 1024px) {
        display: none;
    }
`;

const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const SPortfolio = styled(Portfolio)`
    width: 12vw;
`;

const SPortfolioWrapper = styled.div`
    min-width: 16vw;
    position: relative;
    text-align: center;
    margin-bottom: 0.55vw;
    left: -2.8vw;

    &::after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0.725vw;
        left: 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    }

    @media (max-width: 768px), (orientation: portrait) {
        display: none;
    }
`;

export const GraphicDesign = () => {
    return (
        <SGraphicDesignWrapper>
            <SWrapper>
                <SGraphicDesign>graphic/</SGraphicDesign>
                <SGraphicDesign translated>design</SGraphicDesign>
            </SWrapper>
            <SPortfolioWrapper>
                <SPortfolio />
            </SPortfolioWrapper>
        </SGraphicDesignWrapper>
    );
};
