import React from 'react';
import styled from 'styled-components';
import accent from 'images/accent-left.png';
import { ContactSection } from 'components/ContactSection';
import { ViewMyWorkButton } from 'components/ViewMyWorkButton';
import { ImageWithLogo } from 'components/ImageWithLogo';
import { PageTitle } from 'components/PageTitle';
import { GraphicDesign } from 'components/GraphicDesign';

const SMainWrapper = styled.div`
    display: flex;
    height: 100vh;
    position: relative;
    background-color: ${({ theme }) => theme.colors.background};

    @media (max-width: 768px), (orientation: portrait) {
        flex-direction: column;
        height: 100%;
    }
`;

const SWrapperRight = styled.div`
    height: 100%;
    width: 42%;
    display: flex;
    flex-direction: column;
    position: relative;

    &::after,
    &::before {
        content: '';
        position: absolute;
        width: 55%;
        height: 45%;
    }

    &::before {
        top: 0;
        right: -1rem;
        background: ${({ theme }) => theme.colors.rectangle_lighter};
    }

    &::after {
        bottom: 0;
        left: 0;
        background: ${({ theme }) => theme.colors.rectangle};
    }

    @media (max-width: 1024px) {
        &::after {
            width: 70%;
            left: -6rem;
        }
    }

    @media (max-width: 768px), (orientation: portrait) {
        width: 100%;
        order: 1;
        min-height: 250px;
        height: unset;

        &::after {
            display: none;
        }

        &::before {
            width: 41%;
        }
    }
`;

const SWrapperLeft = styled.div`
    height: 100%;
    width: 58%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px), (orientation: portrait) {
        width: 100%;
        order: 2;
        height: unset;
    }
`;

const SSectionWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10% 5% 0 10%;
    height: 45%;

    @media (max-width: 1024px) {
        justify-content: center;
        align-items: center;
        padding: 0;
    }
`;

const SImageWrapper = styled.div`
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
`;

const SAccent = styled.img`
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    pointer-events: none;

    @media (max-width: 768px), (orientation: portrait) {
        height: 40vh;
    }
`;

export const MainPageView = () => {
    return (
        <SMainWrapper>
            <SWrapperLeft>
                <PageTitle />
                <SSectionWrapper>
                    <GraphicDesign />
                    <ViewMyWorkButton />
                </SSectionWrapper>
            </SWrapperLeft>
            <SWrapperRight>
                <SImageWrapper>
                    <ImageWithLogo />
                </SImageWrapper>
                <ContactSection />
            </SWrapperRight>
            <ContactSection mobile />
            <SAccent src={accent} />
        </SMainWrapper>
    );
};
