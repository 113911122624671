import React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import Logo from 'svgs/logo.svg';
import { graphql, useStaticQuery } from 'gatsby';

const SLogoWrapper = styled.div`
    position: absolute;
    bottom: 45%;
    left: -8vw;
    z-index: 6;

    @media (max-width: 1024px) {
        bottom: 75%;
        left: 0;
    }

    @media (max-width: 768px), (orientation: portrait) {
        left: 3vw;
        top: 0;
        bottom: unset;
    }
`;

const SImageWrapper = styled.div`
    width: 90%;
    min-width: 30.625rem;
    background-color: ${({ theme }) => theme.colors.background};
    z-index: 4;
    position: relative;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%;

    & .gatsby-image-wrapper {
        height: 100%;
    }

    @media (max-width: 768px), (orientation: portrait) {
        width: 100%;
        top: 2.75rem;
        height: unset;
        min-width: unset;
        position: relative;
        max-height: 26.25rem;
    }
`;

const SLogo = styled(Logo)`
    height: 21vw;
    width: 16vw;

    @media (max-width: 768px), (orientation: portrait) {
        height: 37vw;
        width: 32vw;
    }
`;

export const ImageWithLogo = () => {
    const image = useStaticQuery(graphql`
        query Mateusz {
            mateusz: file(name: { eq: "mateusz" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
        }
    `);

    return (
        <SImageWrapper>
            <SLogoWrapper>
                <SLogo />
            </SLogoWrapper>
            <GatsbyImage image={getImage(image.mateusz)} alt="Mateusz Kurowski" />
        </SImageWrapper>
    );
};
