import React, { useState } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ViewMyWork from 'svgs/view_my_work.svg';
import ArrowButton from 'svgs/ic-arrow.svg';

const SViewMyWorkButton = styled.button`
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 4;
    margin-top: -1vw;
    padding: 2px;

    @media (max-width: 768px), (orientation: portrait) {
        height: unset;
        margin: 10vw auto;
    }
`;

const SViewMyWork = styled(ViewMyWork)`
    margin-bottom: 0.65vw;
    height: 4.5vw;

    @media (max-width: 768px), (orientation: portrait) {
        margin-bottom: 0.5rem;
        height: 8vw;
    }
`;

const SArrowButton = styled(ArrowButton)`
    height: 12.25vw;
    width: 12.25vw;

    & * {
        transition: 0.3s ease-in-out;
    }
    .circle,
    .arrow {
        ${({ theme, highlight }) => (highlight ? `stroke: ${theme.colors.rectangle}` : null)};
    }

    @media (hover: hover) {
        ${SViewMyWorkButton}:hover & .arrow,
        ${SViewMyWorkButton}:hover & .circle {
            stroke: ${({ theme }) => theme.colors.rectangle_lighter};
        }
        ${SViewMyWorkButton}:hover & .arrow {
            transform: translateX(8%);
        }
    }

    @media (max-width: 768px), (orientation: portrait) {
        height: 25vw;
        width: 25vw;
    }
`;

export const ViewMyWorkButton = () => {
    const [highlight, setHighlight] = useState(false);

    return (
        <AniLink swipe direction="left" to="/czynszomat" top="exit" duration={1}>
            <SViewMyWorkButton onClick={() => setHighlight(true)}>
                <SViewMyWork />
                <SArrowButton highlight={highlight} />
            </SViewMyWorkButton>
        </AniLink>
    );
};
